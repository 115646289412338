import codeSyntaxHighlight from "@toast-ui/editor-plugin-code-syntax-highlight";
import "@toast-ui/editor-plugin-code-syntax-highlight/dist/toastui-editor-plugin-code-syntax-highlight.css";
import "@toast-ui/editor/dist/toastui-editor-viewer.css";
import { Viewer } from "@toast-ui/react-editor";
import Prism from "prismjs";
import "prismjs/themes/prism.css";

interface WysiwygViewerProps {
  contents: string;
  theme?: "light" | "dark";
  style?: any;
}

const WysiwygViewer = ({ contents, theme }: WysiwygViewerProps) => {
  return (
    <div>
      <Viewer
        initialValue={contents}
        theme={theme}
        plugins={[[codeSyntaxHighlight, { highlighter: Prism }]]}
      />
    </div>
  );
};
export default WysiwygViewer;
