import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Form, Input } from "antd";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { apiAxios } from "../../api";
import { showMessage } from "../../util";

const LogInForm = ({ setIsModalVisible }: any) => {
  const { register, handleSubmit } = useForm();
  const [loading, setLoading] = useState(false);
  const login = async (param: any) => {
    setLoading(true);
    apiAxios
      .post("/api/auth/login", param)
      .then((response) => {
        if (response.data.success === true) {
          setLoading(false);
          window.location.href = "/";
        }
      })
      .catch((error: any) => {
        showMessage(error.response.data.data);
        setLoading(false);
        return error.response.data;
      });
  };

  return (
    <>
      <Form
        onFinish={handleSubmit(login)}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 14 }}
        layout="horizontal"
        initialValues={{ size: "small" }}
        size={"small"}
      >
        <Form.Item colon={false} label="Email">
          <Input
            type="text"
            prefix={<UserOutlined className="site-form-item-icon" />}
            {...register("username")}
          />
        </Form.Item>
        <Form.Item colon={false} label="Password">
          <Input
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            {...register("password")}
          />
        </Form.Item>
        <div style={{ paddingBottom: "10px" }}>
          <Button
            style={{ float: "right" }}
            type="link"
            htmlType="submit"
            loading={loading}
          >
            Log in
          </Button>
          <Button
            style={{ float: "right" }}
            type="link"
            onClick={() => setIsModalVisible(false)}
          >
            Close
          </Button>
        </div>
      </Form>
    </>
  );
};
export default LogInForm;
