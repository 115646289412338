import mipong from "../../assets/images/mipong.png"

import { BackTop, Breadcrumb, Divider, Skeleton } from "antd";
import { useContext, useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import { AppContext } from "../..";
import {apiAxios, apiUrl} from "../../api";
import PostDetailStyle from "../../assets/styles/post-detail-style";
import { Member } from "../../dto/Member";
import { Post } from "../../dto/Post";
import {showMessage, showSuccessMessage} from "../../util";
import CommentForm from "../comment/CommentForm";
import CommentList from "../comment/CommentList";
import MainContent from "../common/MainContent";
import MarkdownViewer from "../common/MarkdownViewer";
import WysiwygViewer from "../common/WysiwygViewer";
import useConfirm from "../hooks/UseConfirm";
import PDFViewer from "../common/PDFViewer";

const PostDetail = ({ match }: any) => {
  const { type, postId } = useMemo(() => match.params, [match.params]);
  const { isDarkMode, isAdmin, userId, isLoggedIn, isMobile } =
    useContext(AppContext);
  const [post, setPost] = useState<Post>();
  const [, setTags] = useState<string[]>([]);
  const [member, setMember] = useState<Member>();
  const [comments, setComments] = useState<any[]>([]);
  const [directoryNames, setDirectoryNames] = useState<string[]>([]);

  const getPost = async () => {
    setPost(undefined);
    const { data } = await apiAxios
      .post(`/api/post/${postId}`)
      .catch((error: any) => {
        showMessage(error.response.data.data);
        window.location.href = "/";

        return error.response.data;
      });
    setPost(data.data.post);
    setTags(data.data.tags);
    setMember(data.data.member);
    setComments(data.data.comments);
    setDirectoryNames(data.data.directoryNames);
  };

  const sharePost = () => {
    const url = `${window.location.origin}/share/post/${post?.id}`;
    navigator.clipboard.writeText(url);

    showSuccessMessage("Share Link Copied")
  }

  const deletePost = useConfirm("Delete?", async () => {

    try {
    const hasPdfFile = post?.contents_type === 'pdf' && post?.file_url;

    if(hasPdfFile) {
        await apiAxios.post(`${apiUrl}/upload/pdf/delete`, {
          id: Number(postId),
          url: post?.file_url,
        })
    }

    const { data } = await apiAxios
      .post(`/api/post/delete/${postId}`)

      if(data.success) {
        window.location.href = `/post/${type}`;
      }

    } catch (error) {
      showMessage(error.response.data.data);

      return;
    }
  });

  const getMobileMenubar = () =>
    document.getElementById("mobile-post-detail-index");

  const setMobileMenubar = () => {
    const mobileMenuBar = getMobileMenubar();
    if (mobileMenuBar) {
      const index = document.getElementById("index");

      if (index) {
        index.style.fontSize = "13px";
        index.style.lineHeight = "1.5715";

        const fragment = document.createDocumentFragment();
        fragment.appendChild(index);
        mobileMenuBar.appendChild(fragment);
      }
    }
  };
  const resetMobileMenubar = () => {
    const mobileMenuBar = getMobileMenubar();
    if (mobileMenuBar) {
      mobileMenuBar.innerHTML = "";
    }
  };

  const withSummary = (contents: string) => {
    return ["...", contents, "..."].join("");
  };

  const getSummary = (contents: string) => {
    const replaced = contents.replace(/&nbsp/g, "");

    const longSentence = replaced
      .match(/[\s,?가-힣a-zA-Z0-9]{30,}\./);

    if (longSentence) {
      return longSentence[0] + "..";
    }

    const shortSentence = replaced
      .match(/[\s,?가-힣a-zA-Z0-9]{10,}\./);

    if (shortSentence) {
      return shortSentence[0] + "..";
    }

    const firstWord = replaced
      .match(/[\s,?가-힣a-zA-Z0-9]{30,}/);

    if (firstWord) {
      return withSummary(firstWord[0]);
    }

    const korean = replaced.match(/[\s,?가-힣]{5,}/);
    if(korean) {
      return withSummary(korean[0])
    }

    return withSummary(contents.slice(0, 30));
  };


  useEffect(() => {
    window.scrollTo(0, 0);
    getPost().then(isMobile ? setMobileMenubar : undefined);
    return isMobile ? resetMobileMenubar : undefined;
  }, [postId, isDarkMode]);

  return (
    <MainContent type={type}>
      <Helmet>
        <title>{post?.title}</title>
        <meta property="og:title" content={post?.title}/>
        <meta property="og:description" content={getSummary(post?.contents ?? "")}/>
        <meta property="og:image" content={mipong}/>
      </Helmet>
      <PostDetailStyle/>
      <BackTop/>
      <h2 style={{margin: '5px 0px'}}>{post?.title}</h2>
      {/*{*/}
      {/*  <Breadcrumb separator=">">*/}
      {/*    {directoryNames.map((name, idx) => (*/}
      {/*      <Breadcrumb.Item key={`directory-${idx}`}>*/}
      {/*        {name}*/}
      {/*      </Breadcrumb.Item>*/}
      {/*    ))}*/}
      {/*  </Breadcrumb>*/}
      {/*}*/}
      <div>
        <span>{member?.username}</span>

        <span style={{float: "right"}}>
          {post?.regdate.slice(0, 10).replace(/-/g, "")}
        </span>
      </div>
      {post && (
      <div>
        <a onClick={sharePost}>
          공유하기
        </a>
        {post.contents_type === 'pdf' && (
          <>
            &nbsp; | &nbsp;
            <a href={post.file_url}>다운로드</a>
          </>
        )}
        {(isAdmin || post?.member_id === userId) && (
          <>
            &nbsp; | &nbsp;
            <Link to={`/post/update/${type}/${postId}`}>수정하기</Link>
            &nbsp; | &nbsp;
            <a onClick={deletePost}>
              삭제하기
            </a>
          </>
        )}
      </div>
      )}


      <Divider/>
      {post ? (
        <>
          {post.contents_type.startsWith("wysiwyg") ? (
            <WysiwygViewer
              theme={isDarkMode ? "dark" : "light"}
              contents={post.contents}
            />
          ) : post.contents_type.startsWith('markdown') ? (
            <MarkdownViewer
              theme={isDarkMode ? "dark" : "light"}
              markdown={post.contents}
            />
          ) : (<PDFViewer
            path={post.file_url}
          />)}

          <h5 style={{float: "right"}}>조회수 {post?.view_count}</h5>

          <h2 style={{marginTop: "30px"}}>Comments</h2>
          <CommentList comments={comments}/>
          {isLoggedIn && <CommentForm postId={postId}/>}
        </>
      ) : (
        <>
          <Skeleton active paragraph={{rows: 5}}/>
          <Skeleton active paragraph={{rows: 3}}/>
          <Skeleton active paragraph={{rows: 10}}/>
        </>
      )}
    </MainContent>
  );
};

export default PostDetail;
