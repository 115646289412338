import codeSyntaxHighlight from "@toast-ui/editor-plugin-code-syntax-highlight";
import "@toast-ui/editor-plugin-code-syntax-highlight/dist/toastui-editor-plugin-code-syntax-highlight.css";
import "@toast-ui/editor/dist/theme/toastui-editor-dark.css";
import "@toast-ui/editor/dist/toastui-editor.css";
import Prism from "prismjs";
import "prismjs/components/prism-bash";
import "prismjs/components/prism-java";
import "prismjs/components/prism-kotlin";
import "prismjs/components/prism-python";
import "prismjs/components/prism-ruby";
import "prismjs/components/prism-sql";
import "prismjs/components/prism-swift";
import "prismjs/components/prism-typescript";
import "prismjs/themes/prism.css";

import { Editor } from "@toast-ui/react-editor";
import { useCallback, useRef } from "react";
import { apiAxios } from "../../api";

interface EditorProps {
  content: string;
  setContent: Function;
  setContentsType: Function;
  contentsType: "wysiwyg" | "markdown";
  theme?: "light" | "dark";
}

const ToastEditor = ({
  contentsType,
  content,
  setContent,
  setContentsType,
  theme,
}: EditorProps) => {
  const editorRef = useRef<any>();

  const onChange = useCallback(
    (editorType) => {
      if (!editorRef.current) {
        return;
      }
      const instance = editorRef.current.getInstance();
      setContent(
        editorType === "markdown" ? instance.getMarkdown() : instance.getHTML()
      );
      setContentsType(editorType);
    },
    [editorRef]
  );

  const uploadImage = async (blob: Blob): Promise<string> => {
    let data = new FormData();
    data.append("name", "image");
    data.append("file", blob);

    const uploadedUrl: string = (
      await apiAxios.post("/upload/image", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    ).data.data;
    return uploadedUrl;
  };

  return (
    <Editor
      ref={editorRef}
      initialEditType={contentsType}
      initialValue={content}
      onChange={onChange}
      // previewStyle="vertical"
      theme={theme}
      height="800px"
      useCommandShortcut={true}
      // toolbarItems={[
      //   ["heading", "bold", "italic", "strike"],
      //   ["hr", "quote"],
      //   ["ul", "ol", "task", "indent", "outdent"],
      //   ["code", "codeblock"],
      //   ["table", "image", "link"],
      // ]}
      plugins={[[codeSyntaxHighlight, { highlighter: Prism }]]}
      hooks={{
        addImageBlobHook: (blob, callback) => {
          uploadImage(blob).then((uploadedImageURL) => {
            callback(uploadedImageURL, "Image");

            if(contentsType === "markdown") {
              const imageMarkdown = `![Image](${uploadedImageURL})`;
              const imageHtml = `<img src="${uploadedImageURL}"  width="400px" />`;

              const instance = editorRef.current.getInstance(); 
              const content = instance.getMarkdown();
              const replacedContent = content.replace(imageMarkdown, imageHtml)

              setContent(replacedContent)
              instance.setMarkdown(replacedContent, true)
            }
          });
          return false;
        },

      }}
    />
  );
};
export default ToastEditor;
