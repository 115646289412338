import { Button, Modal } from "antd";
import { useState } from "react";
import LogInForm from "./LogInForm";

const LogInModal = ({ logInButton }: any) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  return (
    <>
      <span onClick={showModal}>{logInButton}</span>
      <Modal
        title="Log In"
        closable={false}
        visible={isModalVisible}
        footer={null}
      >
        <LogInForm setIsModalVisible={setIsModalVisible} />
      </Modal>
    </>
  );
};
export default LogInModal;
