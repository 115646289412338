import React, { useState } from "react";
import { createContext } from "react";
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import ReactDOM, { hydrate } from "react-dom";
import { apiAxios } from "./api";
import App from "./App";
import { AuthCookie, ThemeCookie } from "./constants/cookie";
import { Themes } from "./constants/theme";
import { IAppContext } from "./types/Context";
import { getCookie, isMobile } from "./util";
import { Settings } from "luxon";

Settings.defaultZone = "Asia/Seoul";

export const AppContext = createContext<IAppContext>({} as any);

const GlobalContextProvider = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(
    getCookie(ThemeCookie) === "dark"
  );
  let [userId, setUserId] = useState(undefined);
  // const [user, setUser] = useState({});

  const getProfile = async (token: string) => {
    apiAxios
      .get("/api/auth/info")
      .then(({ data }: any) => {
        const isAdmin = data.data.type === "admin";
        const isActive = data.data.status === "Y";
        setIsLoggedIn(true);
        setIsAdmin(isAdmin);
        setIsActive(isActive);
        setUserId(data.data.id);
      })
      .catch((err) => console.error(err));
  };
  const jwt = getCookie(AuthCookie);
  if (jwt) {
    getProfile(jwt);
  }

  return (
    <AppContext.Provider
      value={{
        isLoggedIn,
        setIsLoggedIn,
        isActive,
        setIsActive,
        isAdmin,
        setIsAdmin,
        isDarkMode,
        setIsDarkMode,
        isMobile: isMobile(),
        userId,
        setUserId,
        user: {},
      }}
    >
      <ThemeSwitcherProvider
        themeMap={Themes}
        defaultTheme={isDarkMode ? "dark" : "light"}
      >
        <App />
      </ThemeSwitcherProvider>
    </AppContext.Provider>
  );
};

const rootElement = document.getElementById("root");
if (rootElement?.hasChildNodes()) {
  ReactDOM.hydrate(<GlobalContextProvider />, rootElement);
} else {
  ReactDOM.render(<GlobalContextProvider />, rootElement);
}
