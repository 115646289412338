import { useEffect, useMemo, useState } from "react";
import { apiAxios } from "../../api";
import { Button, Form, Input } from "antd";
import React from "react";
import { Content } from "antd/es/layout/layout";
import { PlusOutlined } from "@ant-design/icons";
import DirectoryForm from "./DirectoryForm";

const DirectoryUpdateForm = ({ match }: any) => {
  const { directoryId } = useMemo(() => match.params, [match.params]);
  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const [parentId, setParentId] = useState(undefined);
  const [orderNumber, setOrderNumber] = useState(1);

  const getDirectory = () => {
    apiAxios.get(`/api/directory/${directoryId}`).then((response) => {
      const { data } = response.data;
      setName(data.name);
      setOrderNumber(data.order_number);
      setType(data.type);
      setParentId(data.directory_id);
    });
  };

  const submitForm = async () => {
    apiAxios
      .post(`/api/directory/save`, {
        name,
        type,
        parent_id: parentId,
        id: +directoryId,
      })
      .then((response) => {
        getDirectory();
      });
  };

  const deleteDirectory = async () => {
    apiAxios.post(`/api/directory/delete`, {
      id: +directoryId,
    });
  };

  useEffect(() => {
    getDirectory();
  }, []);

  return (
    <Content style={{ padding: "0 20px", paddingTop: "63px" }}>
      <Form onFinish={submitForm}>
        <Form.Item>
          Name
          <Input
            type="text"
            value={name}
            onChange={(e: any) => setName(e.target.value)}
          />
          OrderNumber
          <Input
            type="text"
            value={orderNumber}
            onChange={(e: any) => setOrderNumber(e.target.value)}
          />
        </Form.Item>
        Parent
        {type && <DirectoryForm type={type} setDirectoryId={setParentId} />}
        <Button style={{ float: "right" }} type="link" htmlType="submit">
          Save
        </Button>
      </Form>
      <Button
        onClick={deleteDirectory}
        style={{ float: "right" }}
        type="link"
        htmlType="submit"
      >
        Delete
      </Button>
    </Content>
  );
};
export default DirectoryUpdateForm;
