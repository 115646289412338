import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { apiAxios } from "../../api";
import { BlogDivider, SidebarSection } from "../../assets/styles/common";

const Tags = ({ type }: any) => {
  const history = useHistory();
  const [tags, setTags] = useState<string[]>([]);

  const getTags = async () => {
    const { data } = await apiAxios.get(`/api/tag?type=${type}`);
    setTags([...data.data.tags.map((t: any) => t.keyword)]);
  };

  const onSelect = (selectedKeys: any, event: any) => {
    const tag = event.node.title;
    if (tag === type) {
      history.push(`/post/${type}`);
      return;
    }
    history.push(`/post/${type}?tag=${tag}`);
  };

  useEffect(() => {
    getTags();
  }, [type]);

  return (
    <SidebarSection>
      <BlogDivider />
      {/*<PostSearch type={type} tags={tags} />*/}

      {tags.map((tag, idx) => (
        <Link key={`tag-${idx}`} to={`/post/${type}?tag=${tag}`}>
          {`#${tag}  `}
        </Link>
        // <div key={`tag-${idx}`}>
        //   {tag === "all" ? (
        //     <Link to={`/post/${type}`}>{tag}</Link>
        //   ) : (
        //
        //   )}
        // </div>
      ))}
    </SidebarSection>
  );
};
export default Tags;
