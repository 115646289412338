import { useEffect, useState } from "react";
import { apiAxios, apiUrl } from "../../api";
import { showMessage } from "../../util";

export const PostIds = () => {
  const [ids, setIds] = useState<number[]>([]);

  useEffect(() => {
    getPostIds();
  }, []);
  const getPostIds = async () => {
    const { data } = await apiAxios.get(`/api/post/ids`).catch((error: any) => {
      showMessage(error.response.data.data);
      return error.response.data;
    });

    setIds(data.data.ids.map((it: { id: number }) => it.id));
  };

  useEffect(() => console.log(ids), [ids]);

  return (
    <div style={{ marginTop: 500 }}>
      {ids.length > 0 && ids.map((id) => <a href={`/post/dev/${id}`}>Link</a>)}
    </div>
  );
};
