import React from "react";
import { Route, useRouteMatch } from "react-router-dom";
import DirectoryUpdateForm from "../directory/DirectoryUpdateForm";

const DirectoryRoute = () => {
  const match = useRouteMatch();

  return (
    <>
      <Route
        path={`${match.path}/update/:directoryId`}
        component={DirectoryUpdateForm}
        exact
      />
    </>
  );
};

export default DirectoryRoute;
