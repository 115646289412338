import {useLayoutEffect, useState} from "react";
import useResizeObserver from '@react-hook/resize-observer';


export const useWidth = (target: any): number => {
  const [width, setWidth] = useState(0);

  useLayoutEffect(() => {
    setWidth(target.current.getBoundingClientRect().width)
  }, [target]);

  useResizeObserver(target, (entry: any) => setWidth(entry.contentRect.width));

  return width;
};
