import React from "react";
import { Route, useRouteMatch } from "react-router-dom";
import MyPage from "../user/MyPage";
import UserList from "../user/UserList";

const UserRoute = () => {
  const match = useRouteMatch();

  return (
    <>
      <Route path={`${match.path}/list`} component={UserList} exact />
      <Route path={`${match.path}/mypage`} component={MyPage} exact />
    </>
  );
};

export default UserRoute;
