import "styled-components";
import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  // Ubuntu Font
  // @import url('https://fonts.googleapis.com/css2?family=Ubuntu+Mono&display=swap');
  // code {
  //   font-family: 'Ubuntu Mono', monospace !important;
  // }

  // Iosevka Font
  // Google Web Font 에서 지원해주지 않아서, Github Repository 에 올려놓음
  @import url('https://h16rkim.github.io/iosevka-webfont/29.0.2/Iosevka.css');
  code {
    font-family: 'Iosevka Web', monospace !important;
  }
  img {
    max-width: 100% !important;
  }
  
  img.mipong {
    vertical-align: middle;
    margin-left: 10px;
    box-shadow: 0 0 0 1px #1f232826;
    border-radius: 50% !important;
    width: 32px;
  }
  
  .center {
    display: block;
    margin: 0px auto;
  }

  .toastui-editor-contents {
    font-size: 14px;
  }
  
  // Code Block
  .toastui-editor-contents pre {
    background-color: #ffffff;
    border: 1px dashed lightgray;
  }
  .ant-tree-switcher {
    width: 0px !important;
  }
  // 메뉴바 뒤에 배경색 생기는 것 방지
  .ant-layout-header {
    background: transparent !important;
  }
  // 메뉴바에 underline 생기는 것 방지
  .ant-menu-horizontal, .ant-menu-item::after, .ant-menu-submenu::after {
    border: none !important;
  }
  .ant-divider-horizontal {
    margin: 12px 0px !important;
  }
  .ant-layout-header {
    padding: 0px !important;
  }
  .ant-btn {
    border-radius: 99em !important;
  }
  div.toastui-editor-contents {
    overflow-x: scroll !important;
  }
  // code-block 안에서 다크모드일 때 색이 이상하게 나오는 버그가 있음
  .token.operator {
    background: transparent !important;
  }
  .no-margin-top {
    margin-top: 0px !important;
  }
  @media only screen and (max-width:500px) {
    .ant-menu-overflow-item {
      padding: 0px 0px 0px 10px !important;
    }
    .toastui-editor-popup {
      left: inherit !important;
      right: 13px !important;
    }
  }
  #index {
    background-color: transparent !important;
    z-index: 1000 !important;
    border: 0px;
  }
`;

export default GlobalStyle;
