import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { apiAxios } from "../../api";

const YearBreadcrumb = ({ type }: any) => {
  const [years, setYears] = useState<number[]>([]);
  const getYears = async () => {
    const { data } = await apiAxios.get(`/api/post/years?type=${type}`);
    setYears(data.data.years);
  };
  useEffect(() => {
    getYears();
  }, [type]);

  return (
    <div style={{ margin: "0px 0px 10px 0" }}>
      <Link
        style={{ float: "right" }}
        key={`type-${type}-all`}
        to={`/post/${type}`}
      >
        All
      </Link>
      &nbsp;

      {years
        .sort((a, b) => Number(b) - Number(a))
        .map((year, idx) => (
            <Link
              style={{ float: "right" }}
              key={`type-${type}-${idx}`}
              to={`/post/${type}?year=${year}`}
            >
              {year.toString()}&nbsp;
            </Link>
        ))}
    </div>
  );
};
export default YearBreadcrumb;
