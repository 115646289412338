import { useRef } from "react";
import { useHistory } from "react-router-dom";

const SearchBar = ({ type }: any) => {
  const history = useHistory();
  const keywordRef = useRef<any>();

  const searchKeyword = () => {
    const keyword = keywordRef.current.value?.trim();
    if (!keyword || keyword.length < 2) {
      alert("2글자 이상 입력해주세요");

      keywordRef.current.value = "";
      keywordRef.current.focus();

      return;
    }

    history.push(`/post/${type}?search=${keyword}`);
  };

  const handleKeydown = (event: any) => {
    if (event.key === "Enter") {
      searchKeyword();
    }
  };

  return (
    <div style={{ height: "11px", width: "100%" }}>
      <span
        onClick={searchKeyword}
        style={{
          fontSize: "11px",
          fontFamily: "'Iosevka Web', monospace",
          border: "0px",
          borderRadius: "0px",
          padding: "0px",
        }}
      >
        🔎
      </span>
      <input
        placeholder="Search Texts"
        ref={keywordRef}
        onKeyDown={handleKeydown}
        style={{
          padding: "0px 12px",
          fontSize: "11px",
          fontFamily: "'Iosevka Web', monospace",
          border: "0px",
          borderRadius: "0px",
          outline: "none",
        }}
      />
    </div>
  );
};
export default SearchBar;
