import { BackTop, Divider, Skeleton, Switch } from "antd";
import * as queryString from "query-string";
import { useContext, useEffect, useMemo, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { AppContext } from "../..";
import { apiAxios } from "../../api";
import { Post } from "../../dto/Post";
import { showMessage } from "../../util";
import MainContent from "../common/MainContent";
import SearchBar from "../common/SearchBar";
import YearBreadcrumb from "../common/YearBreadcrumb";
import PostCard from "./PostCard";

const PostList = ({ match }: any) => {
  const { type } = useMemo(() => match.params, [match.params]);
  const { isActive, isAdmin, isDarkMode } = useContext(AppContext);
  const [posts, setPosts] = useState<Post[]>([]);
  const [page, setPage] = useState(1);
  const [hasNext, setHasNext] = useState(true);
  const [loading, setLoading] = useState(true);
  const [isVisible, setIsVisible] = useState(true);
  const [nextPageLoading, setNextPageLoading] = useState(false);
  const location = useLocation();
  const query = useMemo(() => queryString.parse(location.search), [location]);

  const getPosts = async (nextPage = false) => {
    if (nextPage === false) {
      setLoading(true);
    }

    const { data } = await apiAxios
      .post("/api/post", {
        postType: [type],
        page: nextPage ? page + 1 : 1,
        perpage: 20,
        year: query.year,
        keyword: query.tag,
        search: query.search,
        isVisible: isVisible,
      })
      .catch((error: any) => {
        showMessage(error.response.data.data);
        window.location.href = "/";

        return error.response.data;
      });

    // 리스트 화면은 미리보기이므로, 300 자만 보여준다. (더 보여줘도 어차피 안보임)
    const previewPosts = data.data.posts.map((post: Post) => {
      post.contents = post.contents.slice(0, 700);

      return post;
    });

    setPosts(nextPage ? [...posts, ...previewPosts] : previewPosts);
    setHasNext(data.data.hasNext);
    if (nextPage === false) {
      setLoading(false);
    }
    setNextPageLoading(false);
  };

  useEffect(() => {
    if (nextPageLoading === true) {
      setPage(page + 1);
    }
  }, [nextPageLoading]);

  useEffect(() => {
    setPage(1);
    setLoading(true);
    getPosts(false);
  }, [query, type, isVisible, isDarkMode]);

  useEffect(() => {
    if (!loading && hasNext && nextPageLoading) {
      getPosts(true);
    }
  }, [page]);

  useEffect(() => {
    function onScroll() {
      if (
        hasNext &&
        !nextPageLoading &&
        window.scrollY + document.documentElement.clientHeight >=
          document.documentElement.scrollHeight - 10
      ) {
        setNextPageLoading(true);
      }
    }
    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  return (
    <MainContent type={type}>
      <BackTop />
      {isActive && (
        <div>
          <Link to={`/post/new/${type}`}>new</Link>
          {isAdmin && (
            <Switch
              style={{ float: "right" }}
              size="small"
              onChange={() => setIsVisible(!isVisible)}
              checkedChildren="비공개"
              unCheckedChildren="공개"
            />
          )}
        </div>
      )}
      <YearBreadcrumb type={type} />
      <SearchBar type={type} />
      <Divider  />
      {loading ? (
        <>
          <Skeleton active paragraph={{ rows: 5 }} />
          <Skeleton active paragraph={{ rows: 3 }} />
          <Skeleton active paragraph={{ rows: 10 }} />
        </>
      ) : (
        posts.map((post, idx) => (
          <PostCard key={`post-${idx}`} post={post} type={type} />
        ))
      )}
      {hasNext && nextPageLoading && <Skeleton />}
    </MainContent>
  );
};
export default PostList;
