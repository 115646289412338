import { apiAxios } from "../../api";
import React, { useEffect, useState } from "react";
import { SidebarSection } from "../../assets/styles/common";
import DirectoryTree from "antd/lib/tree/DirectoryTree";
import { Button, Col, Input, Row } from "antd";
import { SettingOutlined } from "@ant-design/icons";

const DirectoryForm = ({ setDirectoryId, type }: any) => {
  const [directories, setDirectories] = useState<any[]>([]);
  const [directoryName, setDirectoryName] = useState<string>("");
  const [parentId, setParentId] = useState<undefined | number>(undefined);

  const getDirectories = async () => {
    const { data } = await apiAxios.get(`/api/post/directories?type=${type}`);
    setDirectories(data.data.directories);
  };
  const onSelect = (selectedKeys: any, event: any) => {
    const directoryId = event.node.directoryId;

    console.log(directoryId);
    setDirectoryId(directoryId);
  };
  const onAddDirectorySelect = (selectedKeys: any, event: any) => {
    const directoryId = event.node.directoryId;

    console.log(directoryId);
    setParentId(directoryId);
  };

  useEffect(() => {
    getDirectories();
  }, []);

  const saveDirectory = async () => {
    await apiAxios
      .post(`/api/directory/save`, {
        parent_id: parentId,
        name: directoryName,
        type,
      })
      .then(({ data }: any) => {
        getDirectories();
      });
  };
  const onRightClick = async (param: { event: any; node: any }) => {
    const { node } = param;
    window.open(
      `${window.location.origin}/directory/update/${node.directoryId}`
    );
  };

  const makeDirectoryData = (directory: any) => {
    return {
      title: directory.name,
      key: `directory-${directory.id}`,
      isLeaf: false,
      children: (directory.Directories ?? []).map((d: any) =>
        makeDirectoryData(d)
      ),
      directoryId: directory.id,
    };
  };

  return (
    <Row>
      <Col lg={12}>
        {directories.length > 0 && (
          <>
            <h4>Directories</h4>
            <DirectoryTree
              switcherIcon={<></>}
              defaultExpandAll={true}
              onSelect={onSelect}
              treeData={directories.map(makeDirectoryData)}
            />
          </>
        )}
      </Col>
      <Col lg={12}>
        <h4>Add Directory</h4>
        <Input
          type="text"
          name="name"
          value={directoryName}
          onChange={(e: any) => setDirectoryName(e.target.value)}
          placeholder="Name"
        />

        <h4>Parent Directory</h4>
        {directories.length > 0 && (
          <>
            <DirectoryTree
              switcherIcon={<></>}
              defaultExpandAll={true}
              onSelect={onAddDirectorySelect}
              onRightClick={onRightClick}
              treeData={directories.map(makeDirectoryData)}
            />
          </>
        )}
        <Button onClick={saveDirectory} style={{ float: "right" }} type="link">
          save
        </Button>
      </Col>
    </Row>
  );
};
export default DirectoryForm;
