import {message, Upload} from "antd";
import {InboxOutlined} from "@ant-design/icons";
import {UploadChangeParam, UploadFile} from "antd/lib/upload/interface";
import * as React from "react";

const PDFDragger = ({
  defaultFileList,
  beforeUpload,
  action,
  onDone,
  onDrop,
  onRemove,
}: {
  defaultFileList?: Array<UploadFile<any>>
  action: string,
  beforeUpload: (file: File) => boolean,
  onDone:  (info: UploadChangeParam) => void,
  onDrop: (event: React.DragEvent<HTMLDivElement>) => void;
  onRemove?: (file: UploadFile<any>) => Promise<void>;
}) => {
  return (
    <Upload.Dragger {...{
      name: 'file',
      defaultFileList: defaultFileList ?? [],
      multiple: false,
      beforeUpload,
      showUploadList: false,
      action: action,
      onChange(info) {
        const { status } = info.file;
        if (status === 'done') {
            onDone(info);
        } else if (status === 'error') {
          message.error(`${info.file.name} file upload failed.`);
        }
      },
      onDrop(e) {
        onDrop(e);
      },
      onRemove(file) {
        if(onRemove) {
          onRemove(file);
        }
      },

    }}>
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">Click or drag PDF file to this area to upload</p>
    </Upload.Dragger>

  );
}

export default PDFDragger;
