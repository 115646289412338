import "@toast-ui/editor-plugin-code-syntax-highlight/dist/toastui-editor-plugin-code-syntax-highlight.css";
import "@toast-ui/editor/dist/toastui-editor-viewer.css";
import Prism from "prismjs";
import "prismjs/components/prism-bash";
import "prismjs/components/prism-java";
import "prismjs/components/prism-kotlin";
import "prismjs/components/prism-python";
import "prismjs/components/prism-ruby";
import "prismjs/components/prism-sql";
import "prismjs/components/prism-swift";
import "prismjs/components/prism-typescript";
import "prismjs/plugins/copy-to-clipboard/prism-copy-to-clipboard";
import "prismjs/prism.js";
import "prismjs/themes/prism.css";

import codeSyntaxHighlight from "@toast-ui/editor-plugin-code-syntax-highlight";

import { Viewer } from "@toast-ui/react-editor";
interface MarkdownViewerProps {
  markdown: string;
  theme?: "light" | "dark";
  style?: any;
}

const MarkdownViewer = ({ markdown, theme }: MarkdownViewerProps) => {
  return (
    <div>
      <Viewer
        initialValue={markdown}
        theme={theme}
        plugins={[[codeSyntaxHighlight, { highlighter: Prism }]]}
      />
    </div>
  );
};
export default MarkdownViewer;
