import pdfToText from 'react-pdftotext'


import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import {Button, Form, Input, Space, Switch, message, Row, Col} from "antd";
import { Content } from "antd/lib/layout/layout";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { AppContext } from "../..";
import {apiAxios, apiUrl} from "../../api";
import {cleanWhitespace, showMessage, showSuccessMessage} from "../../util";
import ToastEditor from "../common/ToastEditor";
import * as queryString from "query-string";
import DirectoryForm from "../directory/DirectoryForm";
import PDFDragger from "../common/PDFDragger";
import useConfirm from "../hooks/UseConfirm";

const PostForm = ({ match }: any) => {
  const { userId, isDarkMode } = useContext(AppContext);
  const { type, id } = useMemo(() => match.params, [match.params]);
  const history = useHistory();
  const location = useLocation();
  const query = useMemo(() => queryString.parse(location.search), [location]);
  const [title, setTitle] = useState("");
  const [contents, setContents] = useState<string | undefined>(undefined);
  const [contentsType, setContentsType] = useState<
    "markdown" | "wysiwyg" | "pdf" | undefined
  >(undefined);
  const [fileUrl, setFileUrl] = useState<string | undefined>(undefined);
  const [isVisible, setIsVisible] = useState(true);
  const [isShareable, setIsShareable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [autoSave, setAutoSave] = useState(query.autoSave === "true");
  const [directoryId, setDirectoryId] = useState(undefined);

  const autoSaveRef = useRef<any>(null);

  const extractPdfText = async (file: any) =>
    pdfToText(file)
      .then(text => text)
      .then(text => cleanWhitespace(text))
      .catch(error => console.error("Failed to extract text from pdf"))

  const deleteFile = useConfirm("Delete File?",
    async (id: number, url: string) => {
      try {
        await apiAxios.post(`${apiUrl}/upload/pdf/delete`, {
          id,
          url,
        });
        setFileUrl("")
        setContents("")
        showSuccessMessage("File deleted successfully")
      } catch (error) {
        showMessage(error.response.data.data);
      }
    }
  )

  const getPost =  () => {
    apiAxios
      .post(`/api/post/auth/${id}`)
      .then(({ data }: any) => {
        setContents(data.data.post.contents);
        setTitle(data.data.post.title);
        setContentsType(data.data.post.contents_type);
        setFileUrl(data.data.post.file_url);
        setIsVisible(data.data.post.is_visible);
        setIsShareable(data.data.post.is_shareable);
        setDirectoryId(data.data.post.directory_id);
      })
      .catch((err) => (window.location.href = "/"));
  };

  const callSubmitAPI = ({
    tags = [],
    refresh = true,
  }: {
    tags?: any;
    refresh?: boolean;
  }) => {
    apiAxios
      .post("/api/post/save", {
        id: +id,
        tags: tags ?? [],
        title,
        is_visible: isVisible,
        is_shareable: isShareable,
        contents,
        post_type: type,
        member_id: userId,
        file_url: fileUrl,
        contents_type: contentsType,
        directory_id: directoryId,
      })
      .then((response) => {
        if (response.data.success === true) {
          setLoading(false);
          if (refresh) {
            history.push(`/post/${type}/${response.data.data.post.id}`);
          } else {
            if (!id) {
              history.push(
                `/post/update/${type}/${response.data.data.post.id}?autoSave=${autoSave}`
              );
            }
          }
        }
      })
      .catch((error: any) => {
        showMessage(error.response.data.data);
        setLoading(false);
        return error.response.data;
      });
  };


  useEffect(() => {
    autoSaveRef.current = () => {
      if (autoSave) {
        setLoading(true);
        callSubmitAPI({
          refresh: false,
        });
      }
    };
  }, [autoSave, contents, isVisible, title]);

  useEffect(() => {
    const autoSaveInterval = setInterval(() => {
      autoSaveRef.current();
    }, 15 * 1000);
    return () => clearInterval(autoSaveInterval);
  }, []);

  useEffect(() => {
    if (id) {
      getPost();
    } else {
      setContents("");
      setContentsType("markdown");
    }
  }, [id]);

  const submitForm = async (param: any) => {
    setLoading(true);
    callSubmitAPI({
      tags: param.tags ?? [],
      refresh: true,
    });
  };

  return (
    <Content style={{ padding: "0 20px", paddingTop: "63px" }}>
      <Form
        onFinish={submitForm}
      >
        <Form.Item label="Title">
          <Input
            type="text"
            name="title"
            value={title}
            onChange={(e: any) => setTitle(e.target.value)}
            placeholder="Title"
          />
        </Form.Item>
        <Form.Item label="File URL" valuePropName="checked">
          <PDFDragger
            action={
              `${apiUrl}/upload/pdf`
            }
            beforeUpload={
              (file: any) => {
                if(fileUrl) {
                  message.error("You can only upload one file!");
                  return false;
                }

                const isPdf = file.type === "application/pdf";
                if (!isPdf) {
                  message.error("You can only upload PDF file!");

                  return false;
                }

                return true;
              }
            }
            onDone={
              (info: any) => {
                message.success(`${info.file.name} file uploaded successfully.`);
                setContentsType('pdf')
                setFileUrl(info.file.response.data)

                extractPdfText(info.file.originFileObj)
                  .then(text => {
                    setContents(text ?? "")
                  })
              }
            }
            onDrop={
              (e: any) => {
                console.log('Dropped files', e.dataTransfer.files);
                setContents("")
              }}
          />
          <Row>
            <Col span={22}>
              <Input
                type="text"
                name="fileUrl"
                value={fileUrl}
                onChange={(e: any) => setFileUrl(e.target.value)}
                placeholder="PDF File URL"
              />

            </Col>
            <Col span={2}>
              {
                fileUrl && (
                  <Button
                    type="link"
                    onClick={() => {
                      deleteFile(Number(id), fileUrl)
                    }}
                  >
                    Delete
                  </Button>
                )
              }
            </Col>
          </Row>

        </Form.Item>
        <Form.Item label="Visible" valuePropName="checked">
          <Switch
            checked={isVisible}
            onChange={() => setIsVisible(!isVisible)}
          />
        </Form.Item>
        <Form.Item label="Shareable" valuePropName="checked">
          <Switch
            checked={isShareable}
            onChange={() => setIsShareable(!isShareable)}
          />
        </Form.Item>
        <Form.Item label="Auto Save" valuePropName="checked">
          <Switch checked={autoSave} onChange={() => setAutoSave(!autoSave)} />
        </Form.Item>
        <Form.List name="tags">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, fieldKey, ...restField }) => (
                <Space
                  key={key}
                  style={{ display: "flex", marginBottom: 0 }}
                  align="baseline"
                >
                  <Form.Item
                    {...restField}
                    name={[name, "tag"]}
                    fieldKey={[fieldKey, "tag"]}
                    label="Tag"
                  >
                    <Input placeholder="Tag name" />
                  </Form.Item>

                  <MinusCircleOutlined onClick={() => remove(name)} />
                </Space>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  Add tags
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
        <DirectoryForm setDirectoryId={setDirectoryId} type={type} />
        <button
          type="button"
          onClick={() =>
            navigator.clipboard.writeText(`<pre id="index">
<h3>목차</h3>
<a href="#1">ReactiveX 의 구성요소들</a>
<a href="#2">Observable</a>
<a href="#3">Observer</a>
<a href="#4">Operator</a>
&nbsp;<a href="#4-1">선택 관련 Operator</a>
&nbsp;<a href="#4-2">Transformation Operator</a>
&nbsp;<a href="#4-3">시간 관련 Operator</a>
&nbsp;<a href="#4-4">스트림 결합 Operator</a>
&nbsp;<a href="#4-5">기타 유용한 Operator</a>
<a href="#5">Subject</a>
</pre>`)
          }
          style={{ fontWeight: "bold" }}
        >
          목차 넣기(클립보드에 복사)
        </button>
        {(["markdown", "wysywig"].includes(contentsType ?? "")) && (
          <ToastEditor
            theme={isDarkMode ? "dark" : "light"}
            content={contents ?? ""}
            setContent={setContents}
            contentsType={contentsType as any ?? "markdown"}
            setContentsType={setContentsType}
          />
        )}

        <Button
          style={{ float: "right" }}
          type="link"
          htmlType="submit"
          loading={loading}
        >
          Post
        </Button>
      </Form>
    </Content>
  );
};

export default PostForm;
