import { UserOutlined } from "@ant-design/icons";
import { Comment, Avatar, List, Button } from "antd";
import { useContext } from "react";
import { DateTime } from "luxon";
import { AppContext } from "../..";
import { apiAxios } from "../../api";
import { showMessage } from "../../util";
import useConfirm from "../hooks/UseConfirm";

interface CommentListProps {
  id: number;
  contents: string;
  regdate: Date;
  username: string;
  member_id: number;
}
const CommentList = ({ comments }: { comments: CommentListProps[] }) => {
  const { userId, isAdmin } = useContext(AppContext);

  const deleteComment = useConfirm("Delete?", (id: number) => {
    apiAxios
      .post(`/api/comment/delete/${id}`)
      .then((response) => {
        if (response.data.success === true) {
          window.location.reload();
        }
      })
      .catch((error: any) => {
        showMessage(error.response.data.data);
        return error.response.data;
      });
  });

  const data = comments.map((comment, idx) => ({
    author: comment.username,
    avatar: <Avatar size="small" icon={<UserOutlined />} />,
    content: comment.contents,
    actions:
      isAdmin || userId === comment.member_id
        ? [
            <span
              key={`comment-list-reply-to-${idx}`}
              onClick={() => deleteComment(comment.id)}
            >
              삭제
            </span>,
          ]
        : undefined,
    datetime: DateTime.fromJSDate(new Date(comment.regdate)).toFormat(
      "yyyy-MM-dd HH:mm:ss"
    ),
  }));

  return (
    <>
      <List
        className="comment-list"
        header={`${data.length} replies`}
        itemLayout="horizontal"
        dataSource={data}
        locale={{ emptyText: "No Comments" }}
        renderItem={(item) => (
          <li>
            <Comment
              actions={item.actions}
              author={item.author}
              avatar={item.avatar}
              content={item.content}
              datetime={item.datetime}
            />
          </li>
        )}
      />
    </>
  );
};
export default CommentList;
