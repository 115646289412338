import { Link } from "react-router-dom";
import { Post } from "../../dto/Post";

interface PostProps {
  post: Post;
  type: string;
}

const PostCard = ({ post, type }: PostProps) => {
  const getSummary = (post: Post) => {
    const contents = post.contents;
    const replaced = contents.replace(/&nbsp/g, "");

    const longSentence = replaced
      .match(/[\s,?가-힣a-zA-Z0-9]{30,}\./);

    if (longSentence) {
      return longSentence[0] + "..";
    }

    const shortSentence = replaced
      .match(/[\s,?가-힣a-zA-Z0-9]{10,}\./);

    if (shortSentence) {
      return shortSentence[0] + "..";
    }

    const firstWord = replaced
      .match(/[\s,?가-힣a-zA-Z0-9]{30,}/);

    if (firstWord) {
      return withSummary(firstWord[0]);
    }

    const korean = replaced.match(/[\s,?가-힣]{5,}/);
    if(korean) {
      return withSummary(korean[0])
    }

    return withSummary(contents.slice(0, 30));
  };

  const withSummary = (contents: string) => {
    return ["...", contents, "..."].join("");
  };

  return (
    <>
      <Link to={`/post/${type}/${post.id}`}>
        <span style={{fontWeight: 600}}>{post.regdate.slice(0, 10).replace(/-/g, '')} - {post.title}</span>
        <br/>
        <span style={{ color: "#000000a6", fontStyle: "italic", fontSize: '0.9em' }}>{getSummary(post)}</span>
        <br />
        <br />

        {/* <Card
          size="small"
          title={post.title}
          style={{
            height: '150px',
            overflow: "hidden",
            marginBottom: "5px",
          }}
          hoverable={true}
        >
          {post.contents_type.startsWith("wysiwyg") ? (
            <WysiwygViewer
              theme={isDarkMode ? "dark" : "light"}
              contents={post.contents}
            />
          ) : (
            <MarkdownViewer
              theme={isDarkMode ? "dark" : "light"}
              markdown={post.contents}
            />
          )}
        </Card> */}
      </Link>
    </>
  );
};
export default PostCard;
