import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import React, { useRef, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import styled from "styled-components";
import {useWidth} from "../hooks/UseWidth";
import {Affix, Col, Row, Slider} from "antd";



// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/restrict-template-expressions
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

type PDFViewerProps = {
  path: string;
};

const PDFViewer = ({ path }: PDFViewerProps) => {
  const SLIDER_COEFFICIENT = 5;
  const [numPages, setNumPages] = useState<number>(0);
  const [scale, setScale] = useState<number>(1.0);
  const wrapperDiv = useRef(null);
  const width = useWidth(wrapperDiv);

  const onDocumentLoadSuccess = ({ numPages }: any) => {
    setNumPages(numPages);
  };

  const onDocumentError = (error: Error) => {
    console.error("pdf viewer error", error);
  };

  const onDocumentLocked = () => {
    console.error("pdf locked");
  };

  return (
    <div ref={wrapperDiv}>
      <Affix offsetTop={8}>
        <Row>
          <Col xs={16} md={16} lg={18} xl={18}></Col>
          <Col xs={8} md={8} lg={6} xl={6}>
            <Slider
              min={0 * SLIDER_COEFFICIENT + 1}
              max={2 * SLIDER_COEFFICIENT}
              tooltipVisible={false}
              onChange={(val) => setScale(val / SLIDER_COEFFICIENT)}
              defaultValue={SLIDER_COEFFICIENT * 1}
              disabled={false}
            />
          </Col>
        </Row>
      </Affix>
      <Document
        file={path}
        onLoadSuccess={onDocumentLoadSuccess}
        onLoadError={onDocumentError}
        onPassword={onDocumentLocked}
      >
      {Array.from(new Array(numPages), (_, index) => {
          return (
            <Page
              key={`page_${index + 1}`}
              pageNumber={index + 1}
              devicePixelRatio={window.devicePixelRatio}
              width={width}
              renderMode={"canvas"}
              renderAnnotationLayer={true}
              renderTextLayer={true}
              scale={scale}
            />
        );
        })}
      </Document>
    </div>
);
};

export default PDFViewer;
