import { UserOutlined } from "@ant-design/icons";
import { Avatar, Button, Comment, Input, Mentions } from "antd";
import Form from "antd/lib/form/Form";
import TextArea from "antd/lib/input/TextArea";
import { useEffect } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { apiAxios } from "../../api";
import { showMessage } from "../../util";
import useInput from "../hooks/UseInput";

const CommentForm = ({ postId }: any) => {
  // const { register, handleSubmit } = useForm();
  const [loading, setLoading] = useState(false);
  const [contents, setContents] = useState("");
  const [mentioned, setMentioned] = useState<string[]>([]);
  const [users, setUsers] = useState([]);

  const getUsers = () => {
    apiAxios.get("/api/user/all").then(({ data }: any) => {
      setUsers(data.data.users);
    });
  };

  useEffect(() => {
    getUsers();
  }, []);

  const onChange = (value: string) => {
    setContents(value);
  };
  const onMention = (e: any) => {
    setMentioned([...mentioned, e.value]);
  };

  const submitComment = () => {
    setLoading(true);
    apiAxios
      .post("/api/comment/save", {
        contents,
        post_id: +postId,
        mentioned,
      })
      .then((response) => {
        if (response.data.success === true) {
          setLoading(false);
          window.location.reload();
        }
      })
      .catch((error: any) => {
        showMessage(error.response.data.data);
        setLoading(false);
        return error.response.data;
      });
  };

  return (
    <Form onFinish={submitComment}>
      <Comment
        avatar={<Avatar size="small" icon={<UserOutlined />} />}
        // content={<TextArea allowClear onChange={handleContentsChange} />}
        content={
          <Mentions onSelect={onMention} onChange={onChange}>
            {users.map((user: any, idx) => (
              <Mentions.Option key={`user-${idx}`} value={user.username}>
                {user.username}
              </Mentions.Option>
            ))}
          </Mentions>
        }
      />
      <Button
        style={{ float: "right" }}
        type="link"
        htmlType="submit"
        loading={loading}
      >
        save
      </Button>
    </Form>
  );
};
export default CommentForm;
