import Layout, { Content } from "antd/lib/layout/layout";
import Sider from "antd/lib/layout/Sider";
import { Link } from "react-router-dom";
import UserList from "../user/UserList";

const SettingMain = () => {
  return (
    <Content
      style={{
        padding: "0 20px",
        marginTop: "63px",
      }}
    >
      <Layout style={{ backgroundColor: "transparent" }}>
        <Sider width={100} style={{ backgroundColor: "transparent" }}>
          <Link to="/settings/users">Users</Link>
        </Sider>
        <Layout style={{ backgroundColor: "transparent" }}>
          <Content>
            <UserList />
          </Content>
        </Layout>
      </Layout>
    </Content>
  );
};
export default SettingMain;
