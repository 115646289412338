import React from "react";
import { Route, useRouteMatch } from "react-router-dom";
import PostDetail from "../post/PostDetail";
import PostForm from "../post/PostForm";
import { PostIds } from "../post/PostIds";
import PostList from "../post/PostList";

const PostRoute = () => {
  const match = useRouteMatch();

  return (
    <>
      <Route
        path={`${match.path}/update/:type(dev|diary)/:id`}
        component={PostForm}
        exact
      />
      <Route
        path={`${match.path}/new/:type(dev|diary)`}
        component={PostForm}
        exact
      />
      <Route
        path={`${match.path}/:type(dev|diary)/:postId`}
        component={PostDetail}
        exact
      />
      <Route
        path={`${match.path}/:type(dev|diary)`}
        component={PostList}
        exact
      />
      <Route path={`${match.path}/ids`} component={PostIds} exact />
    </>
  );
};

export default PostRoute;
