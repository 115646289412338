const useConfirm = (
  message: string,
  onConfirm: Function,
  onCancel?: Function
) => {
  const confirmAction = (...params: any) => {
    if (window.confirm(message)) {
      onConfirm(...params);
    } else {
      if (onCancel) {
        onCancel();
      }
    }
  };

  return confirmAction;
};
export default useConfirm;
