import { Divider } from "antd";
import styled from "styled-components";

export const BlogDivider = styled(Divider)`
  margin-top: 8px !important;
  margin-bottom: 8px !important;
`;

export const SidebarSection = styled.section`
  margin-right: 15px;
`;
