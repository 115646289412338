import { Button, Space, Switch, Table } from "antd";
import { useState } from "react";
import { useEffect } from "react";
import { apiAxios } from "../../api";
import { showMessage } from "../../util";
import { DateTime } from "luxon";

const UserList = () => {
  const [users, setUsers] = useState([]);
  useEffect(() => {
    getUsers();
  }, []);

  const changeStatus = (id: number, checked: boolean) => {
    apiAxios
      .post("/api/user/change-status", { id, status: checked ? "Y" : "N" })
      .then(({ data }) => getUsers())
      .catch((error: any) => {
        showMessage(error.response.data.data);
        return error.response.data;
      });
  };

  const changeIsSubscribed = (id: number, checked: boolean) => {
    apiAxios
      .post("/api/user/change-is-subscribed", { id, is_subscribed: checked })
      .then(({ data }) => getUsers())
      .catch((error: any) => {
        showMessage(error.response.data.data);
        return error.response.data;
      });
  };

  const initPassword = (id: number) => {
    apiAxios
      .post("/api/user/admin-change-password", {
        userId: id,
        password: "password",
      })
      .then(({ data }: any) => {
        showMessage("Success");
      })
      .catch((error: any) => {
        showMessage(error.response.data.data);
        return error.response.data;
      });
  };

  const getUsers = async () => {
    apiAxios
      .get("/api/user/all")
      .then(({ data }) => setUsers(data.data.users))
      .catch((error: any) => {
        showMessage(error.response.data.data);
        return error.response.data;
      });
  };

  return (
    <>
      <table style={{ textAlign: "center" }}>
        <thead>
          <tr>
            <th colSpan={1}>ID</th>
            <th colSpan={2}>Username</th>
            <th colSpan={2}>Email</th>
            <th colSpan={3}>type</th>
            <th>status</th>
            <th>subscribed</th>
            <th>regdate</th>
            <th>Init Password ("password")</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user: any) => (
            <tr>
              <td colSpan={1}>{user.id}</td>
              <td colSpan={2}>{user.username}</td>
              <td colSpan={2}>{user.email}</td>
              <td colSpan={3}>{user.type}</td>
              <td>
                <Switch
                  onChange={(e) => changeStatus(user.id, e)}
                  checked={user.status === "Y"}
                />
              </td>
              <td>
                <Switch
                  onChange={(e) => changeIsSubscribed(user.id, e)}
                  checked={user.is_subscribed}
                />
              </td>
              <td>
                {DateTime.fromJSDate(new Date(user.regdate)).toFormat(
                  "yyyy-MM-dd"
                )}
              </td>
              <td>
                <Button type="primary" onClick={() => initPassword(user.id)}>
                  Init Password
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {/* <Table
        columns={columns}
        onChange={handleChange}
        dataSource={users.map((u: any) => ({ ...u, key: u.id }))}
      /> */}
    </>
  );
};

export default UserList;
