import { UserOutlined } from "@ant-design/icons";
import { Form, Input, Switch } from "antd";
import { Content } from "antd/lib/layout/layout";
import { useContext, useEffect } from "react";
import { AppContext } from "../..";
import { apiAxios } from "../../api";
import { showMessage } from "../../util";
import useInput from "../hooks/UseInput";

const MyPage = () => {
  const { userId } = useContext(AppContext);
  const [email, handleEmailChange, setEmail] = useInput("");
  const [password, handlePasswordChange] = useInput("****");
  const [username, handleUsernameChange, setUsername] = useInput("****");
  const [isSubscribed, handleIsSubscribedChange, setIsSubscribed] =
    useInput("");

  useEffect(() => {
    getUserInfo();
  }, []);

  const getUserInfo = () => {
    apiAxios.get("/api/user/get-user").then(({ data }: any) => {
      setEmail(data.data.member.email);
      setIsSubscribed(data.data.member.is_subscribed);
      setUsername(data.data.member.username);
    });
  };

  const changeEmail = () => {
    apiAxios
      .post("/api/user/change-email", { email })
      .then(({ data }: any) => {
        showMessage("Success");
        getUserInfo();
      })
      .catch((error: any) => {
        showMessage(error.response.data.data);
        return error.response.data;
      });
  };
  const changePassword = () => {
    apiAxios
      .post("/api/user/change-password", { password })
      .then(({ data }: any) => {
        showMessage("Success");
        getUserInfo();
      })
      .catch((error: any) => {
        showMessage(error.response.data.data);
        return error.response.data;
      });
  };
  const changeUsername = () => {
    apiAxios
      .post("/api/user/change-username", { username })
      .then(({ data }: any) => {
        showMessage("Success");
        getUserInfo();
      })
      .catch((error: any) => {
        showMessage(error.response.data.data);
        return error.response.data;
      });
  };
  const changeIsSubscribed = (checked: boolean) => {
    setIsSubscribed(checked);
    apiAxios
      .post("/api/user/change-is-subscribed", {
        is_subscribed: checked,
        id: userId,
      })
      .then(({ data }: any) => {
        showMessage("Success");
        getUserInfo();
      })
      .catch((error: any) => {
        showMessage(error.response.data.data);
        return error.response.data;
      });
  };

  return (
    <Content style={{ padding: "0 20px", marginTop: "100px" }}>
      <Form
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 14 }}
        layout="horizontal"
        initialValues={{ size: "small" }}
        size={"small"}
      >
        <Form.Item colon={false} label="Email">
          <Input
            type="text"
            value={email}
            onChange={handleEmailChange}
            addonAfter={<a onClick={changeEmail}>save</a>}
            prefix={<UserOutlined className="site-form-item-icon" />}
          />
        </Form.Item>
        <Form.Item colon={false} label="Password">
          <Input
            type="password"
            value={password}
            onChange={handlePasswordChange}
            addonAfter={<a onClick={changePassword}>save</a>}
            prefix={<UserOutlined className="site-form-item-icon" />}
          />
        </Form.Item>
        <Form.Item colon={false} label="Username">
          <Input
            type="text"
            value={username}
            onChange={handleUsernameChange}
            addonAfter={<a onClick={changeUsername}>save</a>}
            prefix={<UserOutlined className="site-form-item-icon" />}
          />
        </Form.Item>
        <Form.Item colon={false} label="Subscribe">
          <Switch
            onChange={(checked) => changeIsSubscribed(checked)}
            checked={isSubscribed}
          />
        </Form.Item>

        {/* <div style={{ paddingBottom: "10px" }}>
          <Button
            style={{ float: "right" }}
            type="link"
            htmlType="submit"
          >
            Save
          </Button>
        </div> */}
      </Form>
    </Content>
  );
};
export default MyPage;
